import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import Logo from '../assets/logotype.png';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import axios from 'axios';
import errorNotify, { successNotify } from '../services/notify';
import { API_URL } from '../http';

const SLF = () => {

  const [practics, setPractics] = useState(true);
  const [settings, setSettings] = useState([]);
  const [modalVideo, setModalVideo] = useState(true);
  const [buyModal, setBuyModal] = useState(true);
  const [checked, setChecked] = useState(1);
  const [lastName, setLastName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [surName, setSurName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');      
  const [model, setModel] = useState('1');
  const [typePayment, setTypePayment] = useState(1);
  const fullPayment = 5500000;
  const fullPaymentDecorative = '55 000';
  const fullPaymentDeposit = 4500000;
  const fullPaymentDepositDecorative = '45 000';
  const prePayment = 2500000;
  const prePaymentDecorative = '25 000';
  const prePaymentDeposit = 1500000;
  const prePaymentDepositDecorative = '15 000';
  const depositDecorative = '10 000';
  const deposit = 1000000;
  const courseName = '«Сияющие Руки»';
  function ShowModalBuy() {
    setBuyModal(!buyModal);
    if(buyModal) document.getElementById("modalPurchase").style.display = "block";
    else document.getElementById("modalPurchase").style.display = "none";
  }
  function showModalVideo() {
    setModalVideo(!modalVideo);
    if(modalVideo) document.getElementById("myVideo").style.display = "block";
    else document.getElementById("myVideo").style.display = "none";
  }
  function closeModalVideo() {
    setModalVideo(!modalVideo);
    if(modalVideo) document.getElementById("myVideo").style.display = "block";
    else document.getElementById("myVideo").style.display = "none";
  }
  const getSettings = async () => {
    const res = await fetch(
      API_URL + `/s`, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`}
       });
    const data = await res.json();
    setSettings(data);
  };


  useEffect(() => {
    getSettings();
  }, [setSettings]);
  const currentYear = new Date().getFullYear();

  function showpractics() {
    setPractics(!practics);
    if(practics) document.getElementById("footerdrop").style.display = "flex";
    else document.getElementById("footerdrop").style.display = "none";
  }

  function initPayment() {
    if(lastName === '' || firstName === '' || surName === '' || phone === '' || email === '') {
      return errorNotify('Заполните контактные данные!');
    }
    if(model === 0) {
      return errorNotify('Выберите тип обучения!');
    }
    let itemAmount;
    let itemName;
    let purchaseOption;
    if(typePayment === 1) if (model === '1') {
      itemAmount = Number(fullPayment);
      itemName = courseName + '';
      purchaseOption = 2;
    }
    if(typePayment === 1) if (model === '2') {
      itemAmount = Number(prePayment);
      itemName = courseName + '(Аквариум)';
      purchaseOption = 2;
    }
    if(typePayment === 2) if (model === '1') {
      itemName = courseName + ' - депозит';
      itemAmount = Number(deposit);
      purchaseOption = 1;
    }
    if(typePayment === 2) if (model === '2') {
      itemName = courseName + '(Аквариум) - депозит';
      itemAmount = Number(deposit);
      purchaseOption = 1;
    }
    var date = new Date();
    var purchaseDate = ("00" + date.getDate()).slice(-2) + '.' + ("00" + (date.getMonth() + 1)).slice(-2) + '.' + date.getFullYear() + ' ' + ("00" + date.getHours()).slice(-2) + ':' + ("00" + date.getMinutes()).slice(-2);
    const formData = new FormData()
    formData.append('lastName', lastName)
    formData.append('firstName', firstName)
    formData.append('surName', surName)
    formData.append('email', email)
    formData.append('phone', phone)
    formData.append('purchaseType', "2")
    formData.append('itemAmount', itemAmount)
    formData.append('itemName', itemName)
    formData.append('purchaseOption', purchaseOption)
    formData.append('purchaseDate', purchaseDate)
    axios.post(API_URL + '/purchase/create', formData)
    .then(res => {
      window.location.replace(res.data);

    })
    .catch(err => errorNotify(err.response.data.message));
  }

    return (
      <>
          <div id="modalPurchase" className="modal-buy">
            <div className="modal-content">
              <span onClick={() => ShowModalBuy()} className="close">&times;</span>
              <h3 className='modal-feedback-header'>«Сияющие Руки»</h3>
              <hr className="modal-feedback-hr" />
              <p className="modal-title">Модель оплаты</p>
              { typePayment === 1 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment">Внести депозит</button>
              </div>
              : typePayment === 2 ?
              <div className="btn_model_group">
                <button onClick={() => setTypePayment(1)} className="btn-model_fullpayment_alt">Полная оплата</button>
                <button onClick={() => setTypePayment(2)} className="btn-model_prepayment_alt">Внести депозит</button>
              </div>
              : null
              }
              { typePayment === 2 ?
                model === 0 ?
                <div className='disclamer-payment-box'>
                  <p>Невозможно рассчитать стоимость курса, необходимо выбрать модель обучения</p>
                </div>
                :
                <div className='disclamer-payment-box'>
                  <p>Вы вносите депозит в размере 10 000 руб; остаток – {model === '1' ? fullPaymentDepositDecorative : model === '2' ? prePaymentDepositDecorative : null}. Оставшуюся сумму необходимо внести за 2 дня до начала курса</p>
                </div>
                : null
              }
              <input value={lastName} onChange={(e) => setLastName(e.target.value)} className="modal-feedback-input form-size" placeholder="Фамилия" />
              <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className="modal-feedback-input form-size" placeholder="Имя" />
              <input value={surName} onChange={(e) => setSurName(e.target.value)} className="modal-feedback-input form-size" placeholder="Отчество" />
              <input value={phone} onChange={(e) => setPhone(e.target.value)} className="modal-feedback-input form-size" placeholder="Номер телефона" />
              <input value={email} onChange={(e) => setEmail(e.target.value)} className="modal-feedback-input form-size" placeholder="E-Mail" />
              <div className="card_buy-box">
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Курс:</p>
                  <p className='card_buy-confirm-text'>«Сияющие Руки»</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Место проведения:</p>
                  <p className='card_buy-confirm-text'>Онлайн</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата начала:</p>
                  <p className='card_buy-confirm-text'>19.01.2024</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Дата окончания:</p>
                  <p className='card_buy-confirm-text'>21.01.2024</p>
                </div>
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-text'>Ведущий курса:</p>
                  <p className='card_buy-confirm-text'>Елена Эра</p>
                </div>
                <hr className='card_buy-hr' />
                <div className='card_buy-confirm'>
                  <p className='card_buy-confirm-header'>К оплате:</p>
                  <p className='card_buy-confirm-header'>{typePayment === 1 && model === '1' ? fullPaymentDecorative + ' ₽' : typePayment === 1 && model === '2' ? prePaymentDecorative + ' ₽' : typePayment === 2 ? depositDecorative + ' ₽' : 'Неизвестно'}</p>
                </div>
              </div>
              <button onClick={() => initPayment()} className='modal-btn-submit'>Перейти к оплате</button>
            </div>
          </div>
        <div id="myVideo" className="modal">
          <div className="modal-content-media">
            <span onClick={() => closeModalVideo()} className="close">&times;</span>
            <h3 className='modal-feedback-header' style={{marginBottom: '30px'}}>О курсе</h3>
            {settings.op_link_yt_1 !== '' ?
            <ReactPlayer
                // Disable download button
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}

                // Disable right click
                onContextMenu={e => e.preventDefault()}

                // Your props
                url={settings.op_link_yt_1}
                className="react-player"
                controls
                width="100%"
                height="460px"
            /> 
            :
            null}
          </div>
        </div>
      <section className="desc-landing">
        <div className='desc-background'>
        </div>
        <div>
          <div className="desc-cards">
            <div className="desc-card">
              <h4 className="desc-card-header">Метод <span>«Сияющие Руки»<sup>®</sup></span></h4>
              <p className="desc-card-text">Я рада предложить вам красивую практику взаимодействия с телом. Самое удивительное в том, что эта практика совершенно бесконтактная. Вы сможете научить свое тело выбирать исцеление, влиять этим решением на себя и мир. </p>
              <div className="desc-card-grp-btn">
                <div className="desc-card-btn">
                  <p>В медицине это назвали метаболизмом, в философии – жизненной силой</p>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
                  </svg>
                </div>
                <div className="desc-card-btn">
                  <p>«Сияющие Сессии», дают возможность прекратить поиск ответов во вне</p>
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h2 className='course-description-header'>Курс «Сияющие Руки» создан для того, чтобы <span>Вы получили больше осознанности</span> для создания с помощью своих рук и тела</h2>
        <p className="course-description-text"><span>Руки имеют доступ к информационной матрице</span>, способны собирать и передавать энергии и информацию со скоростью, не подлежащей понимаю логическими умом, вне времени и пространства.</p>
        <p className="course-description-text"><span>Вы узнаете, как можно через руки создавать такие информационные потоки, в которых тело может выбрать исцеление</span>, и как это можно делать на любом расстоянии. Как можно получить информацию о теле и его процессах другого человека. Вы осознаете, что для вас нет пределов времени, расстояния. Вы энергия, вы музыка в вечности.</p>
        <p className="course-description-text">Метод «Сияющие Руки» универсален, независимо от образования. В связи с чём «Сияющие Сессии» могут изменить мировоззрение буквально по «мановению руки». </p>
        <h2 className='course-description-header-alt'>
          «Почему практики метода «Сияющие Руки» <span>наблюдают чудеса исцеления</span>, и при этом никого не лечат?
        </h2>
        <div className="course-description-slider">
          <p>В Теле есть разные виды рецепторов. <span>Благодаря им мы получаем информацию об окружающем мире</span> и процессах, которые нас с этим миром соединяют.</p>
        </div>
        <div className="course-description-slider-alt">
          <p><span>Наше Тело с удовольствием получает массаж</span>, нам нравится, когда к нам прикасаются. В момент такого воздействия в Теле происходят процессы, описанные как улучшение кровообращения, лимфодренаж и другие полезные процессы.</p>
        </div>
        <div className="course-description-slider">
          <p>Рецепторы, затронутые при этом, <span>связаны с определенным участком головного мозга</span>. Но есть ДРУГИЕ рецепторы. Эти рецепторы совершенно не реагируют на такой вид прикосновений.</p>
        </div>
        <h3 className="course-description-about-header"><span>Как проходит</span> курс?</h3>
        <p className="course-description-about-text">80% практики и 20% теории. Сияющая сессия — это возвращение себе первозданных способностей.</p>
        <p className="course-description-about-text">Обучение онлайн проходит онлайн на платформе Zoom. После прохождения курса вы получите:</p>
      
        <div className="course-description-about-futures-box">
          <div className='course-description-about-futures-i-left'>
            <div className="course-description-about-futures-header">
              01
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
              </svg>
              <p>Мануал с практическими рекомендациями;</p>
            </div>
          </div>
          <div className='course-description-about-futures-i'>
            <div className="course-description-about-futures-header header-sm">
              02
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
              </svg>
              <p className="course-description-about-futures-header-text-alt">Сертификат о прохождении курса;</p>
            </div>
          </div>
          <div className='course-description-about-futures-i-right'>
            <div className="course-description-about-futures-header text-long">
              03
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 0L10.1607 5.83927L16 8L10.1607 10.1607L8 16L5.83927 10.1607L0 8L5.83927 5.83927L8 0Z" fill="#7788BB"/>
              </svg>
              <p>Доступ к платформе для обмена опытом с практиками и для получения обратной связи от фасилитатора.</p>
            </div>
          </div>
        </div>
        <p className="course-description-about-futures-alt-text"><span>Практика пробуждает давно забытые знания</span>, которые у вас уже есть. Без волшебных слов и специальных действий изменяется сознание и отменяются диагнозы.</p>
        <h3 className='course-description-state-header'><span>Более 500 человек уже прошли курс</span> «Сияющие Руки» изменив свою жизнь, жизнь близких и клиентов.</h3>
        <div style={{cursor: 'pointer'}} onClick={() => showModalVideo()} className="player">
          <svg width="23" height="26" viewBox="0 0 23 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 13L0.5 25.9904L0.5 0.00961876L23 13Z" fill="white"/>
          </svg>
        </div>
        <p className="player-text">«Есть только любовь» – лекция Елены Эры</p>






        <div className="container">
          <h2 className="dark_header limit-header">Варианты <span> участия и стоимость</span></h2>
          <h2 className="dark_header limit-header header-cost">55 000 руб.</h2>
          <div className="group_circle-btn btn-cost">
            <a onClick={() => ShowModalBuy()}>
              <button className="circle-btn_fill">
                Оплатить
              </button>
            </a>
            <a href="#programs">
              <button className="circle-btn_dashed">
                Орг. информация
              </button>
            </a>
          </div>
        </div>

        <footer>
        <div className="container">
          <div className="footer_block">
            <div className="footer_block-brand">
              <img className="footer_block-logotype" src={Logo} />
              <p>© {currentYear}, Елена Эра. Все права защищены</p>
            </div>
            <div className="sm-footer-info">
              <a>Расписание</a>
              <a onClick={() => showpractics()}>
                Практики
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"></path></svg>
              </a>
              <div id="footerdrop">
                <Link className="footerdrop-item" to="/shining-hands">- Практики метода «Сияющие руки»</Link>
                <Link className="footerdrop-item" to="/">- Практики метода «Соединение миров»</Link>
              </div>
              <a>Мастера</a>
              <a>Книги</a>
              <a>Блог</a>
            </div>
            <div className="footer_block-info">
              <div className="footer_block-links">
                <Link to="/schedule">Расписание</Link>
                <div className="dropup">
                  <button className="dropupbtn">
                    Практики 
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.0846 4.9585L7.0013 9.04183L2.91797 4.9585" stroke="#1E1B1B" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                  </button>
                  <div className="dropup-content">
                    <a href="#">Практики метода «Сияющие руки»</a>
                    <a href="#">Практики метода «Соединение миров» </a>
                  </div>
                </div>
                <a href="#3">Мастера</a>
                <a href="#3">Книги</a>
                <a href="#3">Блог</a>
              </div>
              <a className="footer_block-policy" href="#3">Политика конфиденциальонсти</a>
            </div>
          </div>
        </div>
      </footer>
      
      </section>
      </>
    );
};

export default observer(SLF);